$primary: #d2a33b;
$secondary: #ffe7b2;
$blue: #1e90ff;
$white: #ffffff;
$lightGray: #f1f1f1;
$darkGray: #4d4d4d;
$grayPanal: #f9f9f9;
$mildGray: #dbd6d6;
$black: #0d0d0d;
$famaly-great-Vibes: "Great Vibes";
$famaly-shippori-mincho: "Shippori Mincho";

$colorGray400: #898989;
$border: 1px solid #4b465c1f;


.video-container {
  position: absolute;
  top: 0;
  width: 100%;
}

.video-container {
  .navber {
    ul {
      li {
        a {
          color: white;
          text-decoration: none;
        }
      }

      li:hover,
      .is-active {
        color: #d6a236;
      }

      .nav-sidebar {
        display: none;
      }
    }
  }
}

.navber {
  height: 77px;
  // background-color: aliceblue;
  display: flex;
  justify-content: center;
  align-items: center;

  .nav-sidebar {
    display: none;
  }

  .nav-logo {
    width: 53%;
  }

  ul {
    list-style-type: none;

    li {
      display: inline;
      margin: 0 10px 0;
      letter-spacing: 0.5px;

      a {
        color: #2f2e2e;
        text-decoration: none;
      }
    }

    li:hover,
    .is-active {
      color: #d6a236;
    }

    // li:hover a{
    //   color: $primary;
    // }
  }
}

.side-fix-menu {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 10;
  text-align: end;
  list-style-type: none;
  margin-right: 21px;

  a {
    color: #3c3c3c;
    text-decoration: none;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 8px;
    height: 18px;

    &:hover {
      li {
        display: block;
        width: auto;
        font-family: "Shippori Mincho";
        font-size: 12px;
      }

      label {
        background-color: #3c3c3c;
        transition: all 0.5s linear;
      }
    }

    li {
      transition: all 0.5s linear;
      display: none;
      width: 0;
    }

    label {
      height: 12px;
      width: 12px;
      border-radius: 50px;
      border: 1px solid #3c3c3c;
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
}

.main-header {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-food {
  background-color: white;
  text-align: center;
  padding: 47px 0;
  height: 586px;
  margin: 100px 0;

  img {
    width: 65%;
  }
}

.from-table-header {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.from-table-btn {
  padding: 9px 26px;
  background-color: white;
  border: 2px solid black;

  a {
    font-size: 18px;
    color: black;
    text-decoration: none;
  }

  &:hover {
    transition: 0.4s;
    background-color: black;
    color: white;

    a {
      transition: 0.6s;
      color: white;
    }
  }
}

.form-control {
  border-radius: inherit !important;
  border: 1px solid #000000 !important;

  &:focus {
    box-shadow: none !important;
  }
}

.services-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;

  /* text-align: center; */
  .service-from-btn {
    padding: 5px 45px;
    background-color: #000000;
    border: none;
    float: right;

    a {
      color: #ffffff;
      text-decoration: none;
    }

    &:hover {
      transition: 0.4s;
      background-color: #4b4c4b !important;
    }

    &:focus {
      outline: none !important;
    }
  }
}

.header-close-btn {
  display: flex;
  justify-content: flex-end;

  .close-btn {
    background-color: transparent;
    border: none;
    font-size: 24px;

    &:focus {
      outline: none;
    }
  }
}

.modal-submit-btn {
  background-color: #d2a33b;
  margin: 6px 0px 43px;
  border: none;
  width: 100%;
  border-radius: 50px;
  padding: 7px;
  color: white;
  font-size: 20px;

  &:focus {
    outline: none;
  }
}

.error-msg {
  height: 22px;
  color: red;
  margin: 0;
  text-align: right;
}

/* team panal */
.team-header {
  text-align: center;
  font-size: 40px;
  padding: 0 0 47px 0;
  font-family: $famaly-shippori-mincho;
}

.team-container {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;

  .team {
    width: 33%;
    height: auto;
    text-align: center;
    margin: 20px 0;

    .team-img {
      width: 65%;
      height: auto;
      border-radius: 50%;
      object-fit: cover;
    }

    .team-name {
      font-family: $famaly-great-Vibes;
      font-size: 26px;
      letter-spacing: 1px;
    }

    .team-para {
      font-family: $famaly-shippori-mincho;
      font-size: 15px;
    }
  }
}

// Auction -----
.auction-header {
  font-family: $famaly-great-Vibes;
  font-weight: bold;
  letter-spacing: 1px;
}

.food-auction-sec {
  background-color: white;
  padding-top: 3rem;
}

// Bulletin ----
.bulletin-img {
  border-radius: 63%;
  width: 180px;
  object-fit: cover;
  height: 180px;
}

.display-header {
  font-size: 4.5rem;
}

// privacy start -----
.privacy-container {
  color: #717171;

  h1,
  h2,
  h3,
  h4,
  h5,
  b {
    color: #000;
    font-weight: bold;
  }

  p,
  li {
    text-align: justify;
  }
}

// privacy end--
//  Term and condition ----
.t-c-table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
}

//  Help Page Start ----!>
.help-sec {
  // background-color: #f7f7f7;
  padding: 40px;
  margin: 0 25px;


  .help-logo {
    width: 20%;
  }

  .help-header {
    margin-bottom: 35px;

    .help-title {
      font-size: 36px;
      font-weight: 800;
      font-family: "Nunito";
    }

    .help-para {
      font-size: 17px;
      font-family: "Rubik";
      color: #6c6c6c;
    }
  }

  .q-ans {
    margin-bottom: 35px;

    .card {
      .card-header {
        background-color: #f7f7f7 !important;

        h5 {
          font-size: 17px;
          color: #424242;
          font-weight: 600;
          font-family: "Nunito";

        }
      }

      .card-body {
        font-family: "Nunito";
        color: #6c6c6c;
      }
    }

    .q-title {
      font-size: 24px;
      font-weight: 800;
      font-family: "Nunito";
      margin-bottom: 15px;
    }
  }

  .help-footer {
    h5 {
      font-size: 17px;
      color: #424242;
      font-weight: 600;
      font-family: "Nunito";
      margin-bottom: 20px;
    }

    .btn-contact {
      background-color: #f2f8ff;
      padding: 8px 20px;
      font-weight: 600;
      border: none;
      font-family: "Nunito";
      border-radius: 4px;
      cursor: pointer;
      margin-bottom: 15px;

      a {
        text-decoration: none;
        color: #0075ff;
        font-size: 15px;
      }
    }

    .btn-mail {
      background-color: #ffecc1;
      padding: 8px 20px;
      font-weight: 600;
      border: none;
      font-family: "Nunito";
      border-radius: 4px;
      cursor: pointer;
      margin-bottom: 15px;

      a {
        text-decoration: none;
        color: #c99521;
        font-size: 15px;
      }
    }
  }
}

//  Help Page End ----!>

//  Term and condition ----
// Media query -----
@media only screen and (max-width: 768px) {
  .navber {
    .nav-sidebar {
      display: block;
    }

    .nav-logo {
      width: 43%;
    }

    ul {
      display: none;
    }
  }

  .display-header {
    font-size: 2.5rem;
  }

  .video-container {
    .navber {
      .nav-sidebar {
        color: white;
      }
    }
  }

  .help-sec {
    padding: 0;
    margin: 0;

    .help-header {
      .help-title {
        font-size: 34px;
      }
    }

    .help-footer {
      .btn-contact {
        width: 100%;
      }

      .btn-mail {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 667px) {
  .sub-food {
    height: 586px;
    margin: 50px 0;
  }

  .display-header {
    font-size: 2.5rem;
  }

  .services-container {
    padding: 1rem 10px;
    text-align: center;
  }

  .team-container {
    flex-direction: column;

    .team {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  .main-header {
    height: 80vh;
  }
}

@media only screen and (max-width: 667px) and (orientation: portrait) {
  .main-header {
    height: 20vh;
    padding-bottom: 7px;

    .head-content {
      font-size: 22px;
    }

    .sub-content {
      font-size: 14px;
    }
  }

  .food-auction-sec {
    padding-top: 0rem;
  }

  .display-header {
    font-size: 1.5rem;
  }

  .sub-food {
    height: 586px;
    margin: 20px 0;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .main-header {
    height: 64vh;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .main-header {
    height: 34vh;
  }
}

.outlet-report-sec {
  background: #ffffff;

  .outlet-Report-card {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

    .box-total {
      background-color: white;
      padding: 10px;
      text-transform: capitalize;
      font-weight: 500;
      color: $colorGray400;
      overflow: hidden;
      border-radius: 4px;
      border: $border;

      .total-manu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'Inter';

        .left-icon {
          margin-right: .5rem;
        }

        .total-cout {
          font-size: 21px;
        }

        .right-bar {
          display: flex;
          align-items: flex-end;
          margin-right: .2rem;

          .bar {
            width: 4px;
            margin: 2px;
          }

          .bar-1 {
            height: 12px;
          }

          .bar-2 {
            height: 19px;
          }

          .bar-3 {
            height: 15px;
          }

          .bar-4 {
            height: 10px;
          }

          .bar-5 {
            height: 14px;
          }

          .bar-6 {
            height: 8px;
          }

          .bar-7 {
            height: 18px;
          }
        }
      }
    }
  }

  .outlet-report-sub-tab {
    background: #FFFFFF;

    .outlet-report-list-container {
      display: grid;
      gap: 15px;
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));

      .outlet-report-list {
        padding: 13px;
        text-transform: capitalize;
        font-weight: 500;
        font-size: .875rem;
        overflow: hidden;
        border-radius: 6px;
        margin-bottom: 15px;
        border: $border;

        .user-name {
          color: #7a7a7a;
          font-size: 17px;
          margin-bottom: 0;
        }

        .text-teal {
          color: #009688;
          margin: 15px 0 5px;
          display: flex;
          align-items: center;
          white-space: nowrap;
          font-size: .875rem;

          .tag {
            color: #009688;
            background-color: #00968829;
            padding: 3px 6px;
            border-radius: 4px;
          }
        }
      }
    }
  }
}

.btn-group-outline {
  padding: 3px 21px;
  white-space: nowrap;
  border: 1px solid $secondary;

  &:hover {
    // box-shadow: 0 8px 6px -8px black;
    background-color: $secondary;
    color: $primary;
  }

  &:active {
    background-color: $secondary;
    color: $primary;
  }

  &:focus {
    background-color: $secondary;
    color: $primary;
  }
}

.btn-group-list {
  color: $darkGray;
  padding: 3px 21px;
  white-space: nowrap;
  border: 1px solid $secondary;

  &:hover {
    // box-shadow: 0 8px 6px -8px black;
    background-color: $secondary;
    color: $primary;
  }

  &:active {
    background-color: $secondary;
    color: $primary;
  }

  &:focus {
    background-color: $secondary;
    color: $primary;
  }
}

.pagination {
  justify-content: flex-end;

  .active {
    background-color: $primary;
    color: white;
  }

  li {
    padding: 2px 10px;
    border: 1px solid $primary;
    color: $primary;
  }
}