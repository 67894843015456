:root {
  --famaly-great-Vibes: "Great Vibes";
  --famaly-shippori-mincho: "Shippori Mincho";
  --grayPanel: #f9f9f9;

  --primary: #d2a33b;
  --secondary: #ffe7b2;
  --blue: #1e90ff;
  --white: #ffffff;
  --lightgray: #f1f1f1;
  --darkgray: #5c5c5c;
  --mildgray: #dbd6d6;
  --black: #0d0d0d;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Arial;
  font-size: 17px;
  font-family: roboto;
  scroll-behavior: smooth;
}

#myVideo {
  width: 100%;
  position: fixed;
  /* z-index: -1; */
}

.underline {
  width: 72px;
  border: 2px solid white;
}

.underline-black {
  width: 60px;
  border: 1px solid rgb(10, 10, 10);
}

.food-auction-video {
  width: 100%;
  height: 100%;
}

.font-15 {
  font-size: 15px;
}

.font-25 {
  font-size: 25px;
}

.f-sans-serif {
  font-family: sans-serif;
}

.d-f-center {
  display: flex;
  justify-content: center;
}

/* .is-active {
  color: #d6a236;
} */

.food-ad {
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.head-content {
  font-family: var(--famaly-great-Vibes);
  font-size: 41px;
  letter-spacing: 2px;
}

.sub-content {
  font-family: var(--famaly-shippori-mincho);
}

.bg-white {
  background-color: white;
}

.bg-gray {
  background-color: var(--grayPanel);
}

.text-white {
  color: white;
}

.h-100vh {
  height: 100vh;
}

.from-table-container {
  padding: 30px 85px;
}

.from-para {
  font-family: sans-serif;
  font-size: 16px;
  line-height: 1.2em;
  color: #717171;
  margin-top: 25px;
}

.quotes {
  font-size: 177px;
  font-weight: bold;
}

.quotes-para {
  font-size: 39px;
  margin-top: -104px;
}

.quotes-sub-img {
  background-color: white;
  border: 2px solid gray;
  border-radius: 100%;
  width: 246px;
  height: 246px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.q-img {
  width: inherit;
  height: inherit;
  border-radius: inherit;
  object-fit: cover;
}

.details-image {
  position: relative;
  width: 25%;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: 256px;
  transition: .5s ease;
  backface-visibility: hidden;
  object-fit: cover;
}

.img-hover-sec {
  display: flex;
  flex-direction: row;
}

.middle {
  display: none;
  transition: .5s ease;
  background-color: #7a7d7c96;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

.details-image:hover .middle {
  display: block;
}

.text {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  color: white;
  height: 256px;
  font-size: 16px;
  padding: 16px 32px;
}

.hover-icons {
  height: 53px;
  width: 100%;
  padding: 0px 29px;
  color: #fff;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer {
  background-image: linear-gradient(#fafafa, #f1f4f7, #d9e1e8);
  text-align: center;
  padding: 18px 0;
  font-size: 14px;
  color: #9e9fa0;
}

@media only screen and (max-width: 667px) {
  .img-hover-sec {
    flex-direction: column;
  }

  .details-image {
    width: 100%;
  }

  .from-table-container {
    padding: 10px 3px;
    margin: 14px;
  }
}

@media only screen and (max-width: 667px) and (min-width: 375px) and (orientation : landscape) {}

.is-active-btn {
  background-color: var(--secondary);
  color: #d6a236;
}